import { Atom, state } from ":mods";
import { TUTORS_FORM_STEPS } from "../../const";
import { FormPageProps } from "../../model";
import { createEffect, createSignal } from "solid-js";
import { getTutorTopics } from "../../api";
export function TalkingTopicPage({ onFormSubmit }: FormPageProps) {
  const [selectedTopics, setSelectedTopics] = createSignal([]);
  const $topicsList = state.create([]);
  const $db_topics = state.createAsync(getTutorTopics);
  createEffect(() => {
    if ($db_topics.state.success) {
      const topics = $db_topics.value?.data?.[0]?.data;
      $topicsList.set(topics);
    }
    console.log(selectedTopics());
  });

  const onTopicSelected = ({ currentTarget }) => {
    const value = parseInt(currentTarget.id);
    if (selectedTopics().find((topic) => topic == value)) {
      setSelectedTopics(selectedTopics().filter((topic) => topic != value));
      return;
    }
    setSelectedTopics([...selectedTopics(), value]);
  };
  const onSubmit = () => {
    const ans = {
      topics: selectedTopics(),
    };
    onFormSubmit(ans);
  };
  return (
    <section class="flex flex-col justify-center items-center bg#paper w-screen text-16px">
      <Atom.CircleProgress.CircleProgressbar steps={TUTORS_FORM_STEPS} current_step={1} />
      <div class="w-846px text-16px">
        <p class="pb-12px">
          If you have a passion for museums and cultural heritage, and possess expertise in areas such as: [INSERT
          TOPICS HERE] we invite you to submit your details for consideration.
        </p>
        <p class="pt-15px">
          This is a unique opportunity to expand your professional network, gain exposure to new opportunities, and
          contribute to the preservation and promotion of cultural heritage in the Kingdom of Saudi Arabia. Don't miss
          out
        </p>
      </div>
      <h1 class="text-28px font-medium my-40px w-628px ">
        Please select up to three topics that you would like to talk about
      </h1>

      <form class="flex flex-col items-left gap-20px text-16px w-630px">
        <div class="flex flex-col gap-10px">
          {/* <FormInputs.Topics.Label title="" class="mb-10px">
            <FormInputs.Topics.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.Topics.Label> */}
          {$topicsList.value?.map((topic) => {
            return (
              <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
                <input
                  type="checkbox"
                  class="w-23px h-23px cursor-pointer"
                  placeholder=""
                  name="talking_topic"
                  id={topic.id}
                  onClick={onTopicSelected}
                />
                <p class="text-16px">{topic.name}</p>
              </div>
            );
          })}
        </div>
        <div class="flex justify-end">
          <Atom.Form.Button
            controls={[]}
            class="disabled:bg#disabled flex items-center justify-center text-16px cursor-pointer bg#p w-88px h-44px text#n-50 mt-20px mb-80px px-30px py-20px"
            onclick={onSubmit}
            statusValid={"Next"}
            statusInvalid={"Next"}
            disabled={selectedTopics().length < 1}
          />
        </div>
      </form>
    </section>
  );
}
